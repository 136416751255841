<template><div><h1 id="manually-flashing-firmware" tabindex="-1"><a class="header-anchor" href="#manually-flashing-firmware"><span>Manually Flashing Firmware</span></a></h1>
<div class="hint-container caution">
<p class="hint-container-title">Caution</p>
<p>Flashing your own firmware has the potential to brick your device. Do not do this unless you are sure you know what you're doing <strong>and have a way to recover from a bad flash</strong>. Some level of knowledge with using the Linux command line is required.</p>
</div>
<p>The steps below assume you are flashing an image named <code v-pre>coreboot.rom</code>; substitute it as necessary.</p>
<ol>
<li>
<p>Download flashrom, cbfstool, and gbb utility, decompress, and ensure they are executable:</p>
<ul>
<li><code v-pre>wget -O flashrom.tar.gz https://mrchromebox.tech/files/util/flashrom_ups_libpci37_20240418.tar.gz &amp;&amp; tar -zxf flashrom.tar.gz &amp;&amp; chmod +x flashrom</code></li>
<li><code v-pre>wget https://mrchromebox.tech/files/util/cbfstool.tar.gz &amp;&amp; tar -zxf cbfstool.tar.gz &amp;&amp; chmod +x cbfstool</code></li>
<li><code v-pre>wget https://mrchromebox.tech/files/util/gbb_utility.tar.gz &amp;&amp; tar -zxf gbb_utility.tar.gz &amp;&amp; chmod +x gbb_utility</code></li>
</ul>
</li>
<li>
<p>Flash your custom ROM</p>
<ul>
<li>Backup your current firmware (just in case things go wrong):
<code v-pre>sudo ./flashrom -p internal -r backup.rom</code></li>
<li>Extract your VPD from your backup:
<code v-pre>./cbfstool backup.rom read -r RO_VPD -f vpd.bin</code></li>
<li>Inject the VPD into your custom ROM:
<code v-pre>./cbfstool coreboot.rom write -r RO_VPD -f vpd.bin</code></li>
<li>Extract your HWID and inject it into the custom ROM (if it exists)
<ul>
<li>if your current firmware came from the firmware utility script run
<ul>
<li><code v-pre>./cbfstool backup.rom extract -n hwid -f hwid.txt</code></li>
</ul>
</li>
<li>if it is stock firmware then run
<ul>
<li><code v-pre>./gbb_utility backup.rom --get --hwid &gt; hwid.txt</code></li>
</ul>
</li>
<li><code v-pre>./cbfstool coreboot.rom add -n hwid -f hwid.txt -t raw</code></li>
</ul>
</li>
<li>Flash your custom firmware:
<ul>
<li>AMD devices: <code v-pre>sudo ./flashrom -p internal -w coreboot.rom</code></li>
<li>Intel devices: <code v-pre>sudo ./flashrom -p internal --ifd -i bios -w coreboot.rom</code></li>
</ul>
</li>
</ul>
</li>
<li>
<p>Reboot</p>
<ul>
<li>Assuming flashrom shows <code v-pre>success</code> at the end of the process, reboot.</li>
</ul>
</li>
</ol>
</div></template>


