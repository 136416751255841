export const SEARCH_INDEX = [
  {
    "title": "Frequently Asked Questions",
    "headers": [
      {
        "level": 2,
        "title": "General",
        "slug": "general",
        "link": "#general",
        "children": [
          {
            "level": 3,
            "title": "I want to buy a Chromebook to run Windows or Linux -- which should I buy?",
            "slug": "i-want-to-buy-a-chromebook-to-run-windows-or-linux-which-should-i-buy",
            "link": "#i-want-to-buy-a-chromebook-to-run-windows-or-linux-which-should-i-buy",
            "children": []
          },
          {
            "level": 3,
            "title": "I just want to boot Linux from USB on my Chromebook, what do I need to do?",
            "slug": "i-just-want-to-boot-linux-from-usb-on-my-chromebook-what-do-i-need-to-do",
            "link": "#i-just-want-to-boot-linux-from-usb-on-my-chromebook-what-do-i-need-to-do",
            "children": []
          },
          {
            "level": 3,
            "title": "I'm trying to exit Developer Mode, but when I press space to exit it says:",
            "slug": "i-m-trying-to-exit-developer-mode-but-when-i-press-space-to-exit-it-says",
            "link": "#i-m-trying-to-exit-developer-mode-but-when-i-press-space-to-exit-it-says",
            "children": []
          },
          {
            "level": 3,
            "title": "How are the MrChromebox and chrultrabook projects related?",
            "slug": "how-are-the-mrchromebox-and-chrultrabook-projects-related",
            "link": "#how-are-the-mrchromebox-and-chrultrabook-projects-related",
            "children": []
          },
          {
            "level": 3,
            "title": "What is coreboot, and how does it relate to ChromeOS device firmware?",
            "slug": "what-is-coreboot-and-how-does-it-relate-to-chromeos-device-firmware",
            "link": "#what-is-coreboot-and-how-does-it-relate-to-chromeos-device-firmware",
            "children": []
          },
          {
            "level": 3,
            "title": "What is ChromeOS automatic update expiration (AUE)?",
            "slug": "what-is-chromeos-automatic-update-expiration-aue",
            "link": "#what-is-chromeos-automatic-update-expiration-aue",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Booting",
        "slug": "booting",
        "link": "#booting",
        "children": [
          {
            "level": 3,
            "title": "I just installed the UEFI firmware, and now my device boots to a black screen that says 'shell' - what do I do?",
            "slug": "i-just-installed-the-uefi-firmware-and-now-my-device-boots-to-a-black-screen-that-says-shell-what-do-i-do",
            "link": "#i-just-installed-the-uefi-firmware-and-now-my-device-boots-to-a-black-screen-that-says-shell-what-do-i-do",
            "children": []
          },
          {
            "level": 3,
            "title": "I'm running Legacy boot firmware now - can I switch to the UEFI firmware?",
            "slug": "i-m-running-legacy-boot-firmware-now-can-i-switch-to-the-uefi-firmware",
            "link": "#i-m-running-legacy-boot-firmware-now-can-i-switch-to-the-uefi-firmware",
            "children": []
          },
          {
            "level": 3,
            "title": "I accidentally updated to the UEFI firmware, and now my existing OS won't boot - what do I do?",
            "slug": "i-accidentally-updated-to-the-uefi-firmware-and-now-my-existing-os-won-t-boot-what-do-i-do",
            "link": "#i-accidentally-updated-to-the-uefi-firmware-and-now-my-existing-os-won-t-boot-what-do-i-do",
            "children": []
          },
          {
            "level": 3,
            "title": "I flashed the firmware and need to install an OS, but my USB drive isn't detected/isn't booting - what now?",
            "slug": "i-flashed-the-firmware-and-need-to-install-an-os-but-my-usb-drive-isn-t-detected-isn-t-booting-what-now",
            "link": "#i-flashed-the-firmware-and-need-to-install-an-os-but-my-usb-drive-isn-t-detected-isn-t-booting-what-now",
            "children": []
          },
          {
            "level": 3,
            "title": "I'm using your UEFI firmware, installed my OS [Linux], and it still boots to the EFI shell - what do I do?",
            "slug": "i-m-using-your-uefi-firmware-installed-my-os-linux-and-it-still-boots-to-the-efi-shell-what-do-i-do",
            "link": "#i-m-using-your-uefi-firmware-installed-my-os-linux-and-it-still-boots-to-the-efi-shell-what-do-i-do",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "OS-Related",
        "slug": "os-related",
        "link": "#os-related",
        "children": [
          {
            "level": 3,
            "title": "I've installed Windows or Linux, and now something doesn't work?",
            "slug": "i-ve-installed-windows-or-linux-and-now-something-doesn-t-work",
            "link": "#i-ve-installed-windows-or-linux-and-now-something-doesn-t-work",
            "children": []
          },
          {
            "level": 3,
            "title": "How can I get audio working under Linux?",
            "slug": "how-can-i-get-audio-working-under-linux",
            "link": "#how-can-i-get-audio-working-under-linux",
            "children": []
          },
          {
            "level": 3,
            "title": "Should I install 32 or 64-bit OS?",
            "slug": "should-i-install-32-or-64-bit-os",
            "link": "#should-i-install-32-or-64-bit-os",
            "children": []
          },
          {
            "level": 3,
            "title": "The Windows installer says none of my partitions are large enough to install Windows - halp?",
            "slug": "the-windows-installer-says-none-of-my-partitions-are-large-enough-to-install-windows-halp",
            "link": "#the-windows-installer-says-none-of-my-partitions-are-large-enough-to-install-windows-halp",
            "children": []
          },
          {
            "level": 3,
            "title": "UEFI is cool and all, but I'd like to revert to ChromeOS. How can I do that?",
            "slug": "uefi-is-cool-and-all-but-i-d-like-to-revert-to-chromeos-how-can-i-do-that",
            "link": "#uefi-is-cool-and-all-but-i-d-like-to-revert-to-chromeos-how-can-i-do-that",
            "children": []
          },
          {
            "level": 3,
            "title": "I'm trying to install Cloudready/ChromeOS Flex and use in a managed environment, but getting an error related to the TPM - how to I fix this?",
            "slug": "i-m-trying-to-install-cloudready-chromeos-flex-and-use-in-a-managed-environment-but-getting-an-error-related-to-the-tpm-how-to-i-fix-this",
            "link": "#i-m-trying-to-install-cloudready-chromeos-flex-and-use-in-a-managed-environment-but-getting-an-error-related-to-the-tpm-how-to-i-fix-this",
            "children": []
          },
          {
            "level": 3,
            "title": "I am trying to boot Linux from USB and getting an error:",
            "slug": "i-am-trying-to-boot-linux-from-usb-and-getting-an-error",
            "link": "#i-am-trying-to-boot-linux-from-usb-and-getting-an-error",
            "children": []
          },
          {
            "level": 3,
            "title": "The Firmware Utility script fails to run under Ubuntu 22.04 (or derivatives) with a file not found error - how to fix?",
            "slug": "the-firmware-utility-script-fails-to-run-under-ubuntu-22-04-or-derivatives-with-a-file-not-found-error-how-to-fix",
            "link": "#the-firmware-utility-script-fails-to-run-under-ubuntu-22-04-or-derivatives-with-a-file-not-found-error-how-to-fix",
            "children": []
          },
          {
            "level": 3,
            "title": "Is ChromeOS Flex a good option for my ChromeOS device which has reached AUE/EOL?",
            "slug": "is-chromeos-flex-a-good-option-for-my-chromeos-device-which-has-reached-aue-eol",
            "link": "#is-chromeos-flex-a-good-option-for-my-chromeos-device-which-has-reached-aue-eol",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Misc",
        "slug": "misc",
        "link": "#misc",
        "children": [
          {
            "level": 3,
            "title": "Why won't the Firmware Utility Script allow me to restore the stock firmware on a ChromeOS device which has reached AUE/EOL?",
            "slug": "why-won-t-the-firmware-utility-script-allow-me-to-restore-the-stock-firmware-on-a-chromeos-device-which-has-reached-aue-eol",
            "link": "#why-won-t-the-firmware-utility-script-allow-me-to-restore-the-stock-firmware-on-a-chromeos-device-which-has-reached-aue-eol",
            "children": []
          },
          {
            "level": 3,
            "title": "Where can I find a SuzyQ cable (SuzyQable)?",
            "slug": "where-can-i-find-a-suzyq-cable-suzyqable",
            "link": "#where-can-i-find-a-suzyq-cable-suzyqable",
            "children": []
          }
        ]
      }
    ],
    "path": "/docs/faq.html",
    "pathLocale": "/",
    "extraFields": [
      "Frequently Asked Questions"
    ]
  },
  {
    "title": "Firmware Utility Script",
    "headers": [
      {
        "level": 2,
        "title": "Overview",
        "slug": "overview",
        "link": "#overview",
        "children": [
          {
            "level": 3,
            "title": "Script Functions Explained",
            "slug": "script-functions-explained",
            "link": "#script-functions-explained",
            "children": []
          }
        ]
      }
    ],
    "path": "/docs/fwscript.html",
    "pathLocale": "/",
    "extraFields": [
      "Firmware Utility Script"
    ]
  },
  {
    "title": "Getting Started",
    "headers": [
      {
        "level": 2,
        "title": "Prerequisites",
        "slug": "prerequisites",
        "link": "#prerequisites",
        "children": []
      },
      {
        "level": 2,
        "title": "What's the TL;DR?",
        "slug": "what-s-the-tl-dr",
        "link": "#what-s-the-tl-dr",
        "children": [
          {
            "level": 3,
            "title": "Dual Booting via RW_LEGACY firmware",
            "slug": "dual-booting-via-rw-legacy-firmware",
            "link": "#dual-booting-via-rw-legacy-firmware",
            "children": []
          },
          {
            "level": 3,
            "title": "Replacing ChromeOS via Full ROM firmware",
            "slug": "replacing-chromeos-via-full-rom-firmware",
            "link": "#replacing-chromeos-via-full-rom-firmware",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Asking for help properly",
        "slug": "asking-for-help-properly",
        "link": "#asking-for-help-properly",
        "children": []
      }
    ],
    "path": "/docs/getting-started.html",
    "pathLocale": "/",
    "extraFields": [
      "Getting Started"
    ]
  },
  {
    "title": "Known Issues",
    "headers": [
      {
        "level": 2,
        "title": "Devices",
        "slug": "devices",
        "link": "#devices",
        "children": [
          {
            "level": 3,
            "title": "Severe Issues",
            "slug": "severe-issues",
            "link": "#severe-issues",
            "children": []
          },
          {
            "level": 3,
            "title": "Minor Issues",
            "slug": "minor-issues",
            "link": "#minor-issues",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Firmware",
        "slug": "firmware",
        "link": "#firmware",
        "children": []
      }
    ],
    "path": "/docs/known-issues.html",
    "pathLocale": "/",
    "extraFields": [
      "Known Issues"
    ]
  },
  {
    "title": "Latest Updates",
    "headers": [
      {
        "level": 2,
        "title": "MrChromebox-2408.1 Release",
        "slug": "mrchromebox-2408-1-release",
        "link": "#mrchromebox-2408-1-release",
        "children": []
      },
      {
        "level": 2,
        "title": "Some small MrChromebox-2408 Updates",
        "slug": "some-small-mrchromebox-2408-updates",
        "link": "#some-small-mrchromebox-2408-updates",
        "children": []
      },
      {
        "level": 2,
        "title": "MrChromebox-2408 Release",
        "slug": "mrchromebox-2408-release",
        "link": "#mrchromebox-2408-release",
        "children": []
      },
      {
        "level": 2,
        "title": "MrChromebox-2405.1 Point Release",
        "slug": "mrchromebox-2405-1-point-release",
        "link": "#mrchromebox-2405-1-point-release",
        "children": []
      },
      {
        "level": 2,
        "title": "Major Website Overhaul!",
        "slug": "major-website-overhaul",
        "link": "#major-website-overhaul",
        "children": []
      },
      {
        "level": 2,
        "title": "MrChromebox-2405 Release",
        "slug": "mrchromebox-2405-release",
        "link": "#mrchromebox-2405-release",
        "children": []
      },
      {
        "level": 2,
        "title": "MrChromebox-4.22.4 Release",
        "slug": "mrchromebox-4-22-4-release",
        "link": "#mrchromebox-4-22-4-release",
        "children": []
      },
      {
        "level": 2,
        "title": "MrChromebox-4.22.3 Release",
        "slug": "mrchromebox-4-22-3-release",
        "link": "#mrchromebox-4-22-3-release",
        "children": []
      },
      {
        "level": 2,
        "title": "Firmware Utility Script back up and running",
        "slug": "firmware-utility-script-back-up-and-running",
        "link": "#firmware-utility-script-back-up-and-running",
        "children": []
      },
      {
        "level": 2,
        "title": "Site Issues and Downtime",
        "slug": "site-issues-and-downtime",
        "link": "#site-issues-and-downtime",
        "children": []
      },
      {
        "level": 2,
        "title": "MrChromebox-4.22.2 Release",
        "slug": "mrchromebox-4-22-2-release",
        "link": "#mrchromebox-4-22-2-release",
        "children": []
      },
      {
        "level": 2,
        "title": "MrChromebox-4.22.1 Release",
        "slug": "mrchromebox-4-22-1-release",
        "link": "#mrchromebox-4-22-1-release",
        "children": []
      },
      {
        "level": 2,
        "title": "MrChromebox-4.22.0 Release",
        "slug": "mrchromebox-4-22-0-release",
        "link": "#mrchromebox-4-22-0-release",
        "children": []
      },
      {
        "level": 2,
        "title": "MrChromebox-4.21.1 Release",
        "slug": "mrchromebox-4-21-1-release",
        "link": "#mrchromebox-4-21-1-release",
        "children": []
      },
      {
        "level": 2,
        "title": "MrChromebox-4.21.0 Release",
        "slug": "mrchromebox-4-21-0-release",
        "link": "#mrchromebox-4-21-0-release",
        "children": []
      },
      {
        "level": 2,
        "title": "coreboot 4.20.1 Release",
        "slug": "coreboot-4-20-1-release",
        "link": "#coreboot-4-20-1-release",
        "children": []
      },
      {
        "level": 2,
        "title": "coreboot 4.20 Release",
        "slug": "coreboot-4-20-release",
        "link": "#coreboot-4-20-release",
        "children": []
      },
      {
        "level": 2,
        "title": "coreboot 4.19 Release",
        "slug": "coreboot-4-19-release",
        "link": "#coreboot-4-19-release",
        "children": []
      },
      {
        "level": 2,
        "title": "coreboot 4.18.2 Update",
        "slug": "coreboot-4-18-2-update",
        "link": "#coreboot-4-18-2-update",
        "children": []
      },
      {
        "level": 2,
        "title": "coreboot 4.18.1 Update",
        "slug": "coreboot-4-18-1-update",
        "link": "#coreboot-4-18-1-update",
        "children": []
      },
      {
        "level": 2,
        "title": "coreboot 4.18 Release",
        "slug": "coreboot-4-18-release",
        "link": "#coreboot-4-18-release",
        "children": []
      },
      {
        "level": 2,
        "title": "coreboot 4.16 Release",
        "slug": "coreboot-4-16-release",
        "link": "#coreboot-4-16-release",
        "children": []
      },
      {
        "level": 2,
        "title": "Pixelbook 4.14 firmware updated",
        "slug": "pixelbook-4-14-firmware-updated",
        "link": "#pixelbook-4-14-firmware-updated",
        "children": []
      },
      {
        "level": 2,
        "title": "coreboot 4.14 Release",
        "slug": "coreboot-4-14-release",
        "link": "#coreboot-4-14-release",
        "children": []
      },
      {
        "level": 2,
        "title": "RW_LEGACY/AltFw support for Jasperlake, Zen2 Chromebooks Added",
        "slug": "rw-legacy-altfw-support-for-jasperlake-zen2-chromebooks-added",
        "link": "#rw-legacy-altfw-support-for-jasperlake-zen2-chromebooks-added",
        "children": []
      },
      {
        "level": 2,
        "title": "coreboot 4.13 Release",
        "slug": "coreboot-4-13-release",
        "link": "#coreboot-4-13-release",
        "children": []
      },
      {
        "level": 2,
        "title": "RW_LEGACY/AltFw support for Cometlake Chromeboxes Added",
        "slug": "rw-legacy-altfw-support-for-cometlake-chromeboxes-added",
        "link": "#rw-legacy-altfw-support-for-cometlake-chromeboxes-added",
        "children": []
      },
      {
        "level": 2,
        "title": "It's always something with Braswell",
        "slug": "it-s-always-something-with-braswell",
        "link": "#it-s-always-something-with-braswell",
        "children": []
      },
      {
        "level": 2,
        "title": "coreboot 4.12 Release",
        "slug": "coreboot-4-12-release",
        "link": "#coreboot-4-12-release",
        "children": []
      },
      {
        "level": 2,
        "title": "Another coreboot 4.11 Respin (4.11.2 point release)",
        "slug": "another-coreboot-4-11-respin-4-11-2-point-release",
        "link": "#another-coreboot-4-11-respin-4-11-2-point-release",
        "children": []
      },
      {
        "level": 2,
        "title": "RW_LEGACY Firmware Added for NOCTURNE",
        "slug": "rw-legacy-firmware-added-for-nocturne",
        "link": "#rw-legacy-firmware-added-for-nocturne",
        "children": []
      },
      {
        "level": 2,
        "title": "UEFI Firmware Added for ATLAS and NOCTURNE",
        "slug": "uefi-firmware-added-for-atlas-and-nocturne",
        "link": "#uefi-firmware-added-for-atlas-and-nocturne",
        "children": []
      },
      {
        "level": 2,
        "title": "Pixelbook Windows backlight control fixed!",
        "slug": "pixelbook-windows-backlight-control-fixed",
        "link": "#pixelbook-windows-backlight-control-fixed",
        "children": []
      },
      {
        "level": 2,
        "title": "AMD eMMC booting fixed!",
        "slug": "amd-emmc-booting-fixed",
        "link": "#amd-emmc-booting-fixed",
        "children": []
      },
      {
        "level": 2,
        "title": "coreboot 4.11 Respin (4.11.1 point release)",
        "slug": "coreboot-4-11-respin-4-11-1-point-release",
        "link": "#coreboot-4-11-respin-4-11-1-point-release",
        "children": []
      },
      {
        "level": 2,
        "title": "coreboot 4.11 + Tiano improvements",
        "slug": "coreboot-4-11-tiano-improvements",
        "link": "#coreboot-4-11-tiano-improvements",
        "children": []
      },
      {
        "level": 2,
        "title": "coreboot 4.10 remix",
        "slug": "coreboot-4-10-remix",
        "link": "#coreboot-4-10-remix",
        "children": []
      },
      {
        "level": 2,
        "title": "coreboot 4.10 release (and more)",
        "slug": "coreboot-4-10-release-and-more",
        "link": "#coreboot-4-10-release-and-more",
        "children": []
      },
      {
        "level": 2,
        "title": "NVRAM? Yep, we got that!",
        "slug": "nvram-yep-we-got-that",
        "link": "#nvram-yep-we-got-that",
        "children": []
      },
      {
        "level": 2,
        "title": "UEFI Full ROM Firmware now available for all Skylake Chromebooks and Kabylake Chromeboxes",
        "slug": "uefi-full-rom-firmware-now-available-for-all-skylake-chromebooks-and-kabylake-chromeboxes",
        "link": "#uefi-full-rom-firmware-now-available-for-all-skylake-chromebooks-and-kabylake-chromeboxes",
        "children": []
      },
      {
        "level": 2,
        "title": "UEFI Full ROM Firmware now available for all Braswell devices (and Stout too!)",
        "slug": "uefi-full-rom-firmware-now-available-for-all-braswell-devices-and-stout-too",
        "link": "#uefi-full-rom-firmware-now-available-for-all-braswell-devices-and-stout-too",
        "children": []
      },
      {
        "level": 2,
        "title": "RW_LEGACY Support added for all ApolloLake Devices! (and the Pixelbook)",
        "slug": "rw-legacy-support-added-for-all-apollolake-devices-and-the-pixelbook",
        "link": "#rw-legacy-support-added-for-all-apollolake-devices-and-the-pixelbook",
        "children": []
      },
      {
        "level": 2,
        "title": "Support added for Link and Lumpy",
        "slug": "support-added-for-link-and-lumpy",
        "link": "#support-added-for-link-and-lumpy",
        "children": []
      },
      {
        "level": 2,
        "title": "New Splash Screen added; BayTrail Audio on Windows fixed",
        "slug": "new-splash-screen-added-baytrail-audio-on-windows-fixed",
        "link": "#new-splash-screen-added-baytrail-audio-on-windows-fixed",
        "children": []
      },
      {
        "level": 2,
        "title": "UEFI Headless Booting is Fixed! CTRL-ALT-DEL added!",
        "slug": "uefi-headless-booting-is-fixed-ctrl-alt-del-added",
        "link": "#uefi-headless-booting-is-fixed-ctrl-alt-del-added",
        "children": []
      },
      {
        "level": 2,
        "title": "EC Firmware updates For Everyone! (almost)",
        "slug": "ec-firmware-updates-for-everyone-almost",
        "link": "#ec-firmware-updates-for-everyone-almost",
        "children": []
      },
      {
        "level": 2,
        "title": "Two Steps Forward, One Step Back?",
        "slug": "two-steps-forward-one-step-back",
        "link": "#two-steps-forward-one-step-back",
        "children": []
      },
      {
        "level": 2,
        "title": "Holy Baytrail, Batman!",
        "slug": "holy-baytrail-batman",
        "link": "#holy-baytrail-batman",
        "children": []
      },
      {
        "level": 2,
        "title": "So Long, SeaBIOS!",
        "slug": "so-long-seabios",
        "link": "#so-long-seabios",
        "children": []
      },
      {
        "level": 2,
        "title": "Another round of UEFI Updates",
        "slug": "another-round-of-uefi-updates",
        "link": "#another-round-of-uefi-updates",
        "children": []
      },
      {
        "level": 2,
        "title": "More UEFI Improvements, new ROMs for Haswell Chromeboxes",
        "slug": "more-uefi-improvements-new-roms-for-haswell-chromeboxes",
        "link": "#more-uefi-improvements-new-roms-for-haswell-chromeboxes",
        "children": []
      },
      {
        "level": 2,
        "title": "Huge improvements in UEFI Firmware! (for all devices with UEFI firmware)",
        "slug": "huge-improvements-in-uefi-firmware-for-all-devices-with-uefi-firmware",
        "link": "#huge-improvements-in-uefi-firmware-for-all-devices-with-uefi-firmware",
        "children": []
      },
      {
        "level": 2,
        "title": "Full ROM support added for Monroe",
        "slug": "full-rom-support-added-for-monroe",
        "link": "#full-rom-support-added-for-monroe",
        "children": []
      },
      {
        "level": 2,
        "title": "Fix for SandyBridge-based Acer C710 (Parrot)",
        "slug": "fix-for-sandybridge-based-acer-c710-parrot",
        "link": "#fix-for-sandybridge-based-acer-c710-parrot",
        "children": []
      },
      {
        "level": 2,
        "title": "Full ROM Support Added for Acer C710 (Parrot)",
        "slug": "full-rom-support-added-for-acer-c710-parrot",
        "link": "#full-rom-support-added-for-acer-c710-parrot",
        "children": []
      },
      {
        "level": 2,
        "title": "Audio fix for Auron devices",
        "slug": "audio-fix-for-auron-devices",
        "link": "#audio-fix-for-auron-devices",
        "children": []
      },
      {
        "level": 2,
        "title": "More Full ROM Firmware Updates",
        "slug": "more-full-rom-firmware-updates",
        "link": "#more-full-rom-firmware-updates",
        "children": []
      },
      {
        "level": 2,
        "title": "Full ROM Firmware Update for HSW/BDW",
        "slug": "full-rom-firmware-update-for-hsw-bdw",
        "link": "#full-rom-firmware-update-for-hsw-bdw",
        "children": []
      },
      {
        "level": 2,
        "title": "New Script Functions: Remove/Restore ChromeOS Bitmaps",
        "slug": "new-script-functions-remove-restore-chromeos-bitmaps",
        "link": "#new-script-functions-remove-restore-chromeos-bitmaps",
        "children": []
      },
      {
        "level": 2,
        "title": "Legacy and UEFI Full ROMs added for all Haswell/Broadwell devices",
        "slug": "legacy-and-uefi-full-roms-added-for-all-haswell-broadwell-devices",
        "link": "#legacy-and-uefi-full-roms-added-for-all-haswell-broadwell-devices",
        "children": []
      },
      {
        "level": 2,
        "title": "Skylake RW_LEGACY support added",
        "slug": "skylake-rw-legacy-support-added",
        "link": "#skylake-rw-legacy-support-added",
        "children": []
      },
      {
        "level": 2,
        "title": "(Preliminary) Braswell support added",
        "slug": "preliminary-braswell-support-added",
        "link": "#preliminary-braswell-support-added",
        "children": []
      },
      {
        "level": 2,
        "title": "Baytrail Windows Update",
        "slug": "baytrail-windows-update",
        "link": "#baytrail-windows-update",
        "children": []
      },
      {
        "level": 2,
        "title": "A few bug fixes, plus Cloudready support",
        "slug": "a-few-bug-fixes-plus-cloudready-support",
        "link": "#a-few-bug-fixes-plus-cloudready-support",
        "children": []
      },
      {
        "level": 2,
        "title": "New Site, New Firmware, New Script Functions!",
        "slug": "new-site-new-firmware-new-script-functions",
        "link": "#new-site-new-firmware-new-script-functions",
        "children": []
      }
    ],
    "path": "/docs/news.html",
    "pathLocale": "/",
    "extraFields": [
      "Latest Updates"
    ]
  },
  {
    "title": "Supported Devices",
    "headers": [
      {
        "level": 2,
        "title": "Determining Device Support",
        "slug": "determining-device-support",
        "link": "#determining-device-support",
        "children": []
      },
      {
        "level": 2,
        "title": "OS Support",
        "slug": "os-support",
        "link": "#os-support",
        "children": []
      },
      {
        "level": 2,
        "title": "Device Listing",
        "slug": "device-listing",
        "link": "#device-listing",
        "children": []
      }
    ],
    "path": "/docs/supported-devices.html",
    "pathLocale": "/",
    "extraFields": [
      "Supported Devices"
    ]
  },
  {
    "title": "Supported Devices",
    "headers": [
      {
        "level": 2,
        "title": "Determining Device Support",
        "slug": "determining-device-support",
        "link": "#determining-device-support",
        "children": []
      },
      {
        "level": 2,
        "title": "OS Support",
        "slug": "os-support",
        "link": "#os-support",
        "children": []
      },
      {
        "level": 2,
        "title": "Device Listing",
        "slug": "device-listing",
        "link": "#device-listing",
        "children": []
      }
    ],
    "path": "/supported-devices/template.html",
    "pathLocale": "/",
    "extraFields": [
      "Supported Devices"
    ]
  },
  {
    "title": "Developer Mode",
    "headers": [
      {
        "level": 2,
        "title": "Overview",
        "slug": "overview",
        "link": "#overview",
        "children": []
      },
      {
        "level": 2,
        "title": "Enabling Developer Mode",
        "slug": "enabling-developer-mode",
        "link": "#enabling-developer-mode",
        "children": [
          {
            "level": 3,
            "title": "Chromebooks/Chromeboxes",
            "slug": "chromebooks-chromeboxes",
            "link": "#chromebooks-chromeboxes",
            "children": []
          },
          {
            "level": 3,
            "title": "Tablets",
            "slug": "tablets",
            "link": "#tablets",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Getting a (Root-capable) Shell",
        "slug": "getting-a-root-capable-shell",
        "link": "#getting-a-root-capable-shell",
        "children": []
      }
    ],
    "path": "/docs/boot-modes/developer.html",
    "pathLocale": "/",
    "extraFields": [
      "Developer Mode"
    ]
  },
  {
    "title": "ChromeOS Boot Modes",
    "headers": [],
    "path": "/docs/boot-modes/",
    "pathLocale": "/",
    "extraFields": [
      "ChromeOS Boot Modes"
    ]
  },
  {
    "title": "Legacy Boot Mode / Alternative Bootloader (AltFw)",
    "headers": [],
    "path": "/docs/boot-modes/legacy.html",
    "pathLocale": "/",
    "extraFields": [
      "Legacy Boot Mode / Alternative Bootloader (AltFw)"
    ]
  },
  {
    "title": "Normal/Verified Boot Mode",
    "headers": [],
    "path": "/docs/boot-modes/normal.html",
    "pathLocale": "/",
    "extraFields": [
      "Normal/Verified Boot Mode"
    ]
  },
  {
    "title": "Recovery Mode",
    "headers": [
      {
        "level": 2,
        "title": "Manually Entering Recovery Mode",
        "slug": "manually-entering-recovery-mode",
        "link": "#manually-entering-recovery-mode",
        "children": [
          {
            "level": 3,
            "title": "Chromebooks",
            "slug": "chromebooks",
            "link": "#chromebooks",
            "children": []
          },
          {
            "level": 3,
            "title": "Chromeboxes",
            "slug": "chromeboxes",
            "link": "#chromeboxes",
            "children": []
          },
          {
            "level": 3,
            "title": "Tablets",
            "slug": "tablets",
            "link": "#tablets",
            "children": []
          }
        ]
      }
    ],
    "path": "/docs/boot-modes/recovery.html",
    "pathLocale": "/",
    "extraFields": [
      "Recovery Mode"
    ]
  },
  {
    "title": "Booting Your OS",
    "headers": [
      {
        "level": 2,
        "title": "Boot Splash",
        "slug": "boot-splash",
        "link": "#boot-splash",
        "children": []
      },
      {
        "level": 2,
        "title": "edk2 Main Menu",
        "slug": "edk2-main-menu",
        "link": "#edk2-main-menu",
        "children": []
      },
      {
        "level": 2,
        "title": "edk2 Boot Menu",
        "slug": "edk2-boot-menu",
        "link": "#edk2-boot-menu",
        "children": []
      },
      {
        "level": 2,
        "title": "edk2 Boot Manager",
        "slug": "edk2-boot-manager",
        "link": "#edk2-boot-manager",
        "children": []
      },
      {
        "level": 2,
        "title": "edk2 EFI Shell",
        "slug": "edk2-efi-shell",
        "link": "#edk2-efi-shell",
        "children": []
      },
      {
        "level": 2,
        "title": "ChromeOS Grub Menu",
        "slug": "chromeos-grub-menu",
        "link": "#chromeos-grub-menu",
        "children": []
      }
    ],
    "path": "/docs/firmware/booting.html",
    "pathLocale": "/",
    "extraFields": [
      "Booting Your OS"
    ]
  },
  {
    "title": "Flashing MrChromebox Custom Firmware",
    "headers": [
      {
        "level": 2,
        "title": "Firmware Flashing TLDR",
        "slug": "firmware-flashing-tldr",
        "link": "#firmware-flashing-tldr",
        "children": []
      },
      {
        "level": 2,
        "title": "Booting",
        "slug": "booting",
        "link": "#booting",
        "children": [
          {
            "level": 3,
            "title": "UEFI Full ROM Firmware",
            "slug": "uefi-full-rom-firmware",
            "link": "#uefi-full-rom-firmware",
            "children": []
          },
          {
            "level": 3,
            "title": "RW_LEGACY Firmware",
            "slug": "rw-legacy-firmware",
            "link": "#rw-legacy-firmware",
            "children": []
          }
        ]
      }
    ],
    "path": "/docs/firmware/flashing-firmware.html",
    "pathLocale": "/",
    "extraFields": [
      "Flashing MrChromebox Custom Firmware"
    ]
  },
  {
    "title": "ChromeOS Device Firmware",
    "headers": [],
    "path": "/docs/firmware/",
    "pathLocale": "/",
    "extraFields": [
      "ChromeOS Device Firmware"
    ]
  },
  {
    "title": "Manually Flashing Firmware",
    "headers": [],
    "path": "/docs/firmware/manual-flashing.html",
    "pathLocale": "/",
    "extraFields": [
      "Manually Flashing Firmware"
    ]
  },
  {
    "title": "Firmware Update Types",
    "headers": [
      {
        "level": 2,
        "title": "RW_LEGACY",
        "slug": "rw-legacy",
        "link": "#rw-legacy",
        "children": []
      },
      {
        "level": 2,
        "title": "BOOT_STUB (now deprecated; listed for historical purposes only)",
        "slug": "boot-stub-now-deprecated-listed-for-historical-purposes-only",
        "link": "#boot-stub-now-deprecated-listed-for-historical-purposes-only",
        "children": []
      },
      {
        "level": 2,
        "title": "(UEFI) Full ROM",
        "slug": "uefi-full-rom",
        "link": "#uefi-full-rom",
        "children": [
          {
            "level": 3,
            "title": "ChromeOS Firmware Boot Flags (GBB Flags)",
            "slug": "chromeos-firmware-boot-flags-gbb-flags",
            "link": "#chromeos-firmware-boot-flags-gbb-flags",
            "children": []
          },
          {
            "level": 3,
            "title": "Custom Firmware / Firmware Updates",
            "slug": "custom-firmware-firmware-updates",
            "link": "#custom-firmware-firmware-updates",
            "children": []
          }
        ]
      }
    ],
    "path": "/docs/firmware/types.html",
    "pathLocale": "/",
    "extraFields": [
      "Firmware Update Types"
    ]
  },
  {
    "title": "Updating MrChromebox Custom Firmware",
    "headers": [],
    "path": "/docs/firmware/updating-firmware.html",
    "pathLocale": "/",
    "extraFields": [
      "Updating MrChromebox Custom Firmware"
    ]
  },
  {
    "title": "Making a Bootable USB",
    "headers": [
      {
        "level": 2,
        "title": "Prerequisites",
        "slug": "prerequisites",
        "link": "#prerequisites",
        "children": []
      },
      {
        "level": 2,
        "title": "Downloading a ISO",
        "slug": "downloading-a-iso",
        "link": "#downloading-a-iso",
        "children": []
      },
      {
        "level": 2,
        "title": "Flashing using Rufus (Windows)",
        "slug": "flashing-using-rufus-windows",
        "link": "#flashing-using-rufus-windows",
        "children": []
      },
      {
        "level": 2,
        "title": "Flashing using Ventoy (Windows/Linux/macOS)",
        "slug": "flashing-using-ventoy-windows-linux-macos",
        "link": "#flashing-using-ventoy-windows-linux-macos",
        "children": []
      },
      {
        "level": 2,
        "title": "Flashing with dd (Linux/macOS)",
        "slug": "flashing-with-dd-linux-macos",
        "link": "#flashing-with-dd-linux-macos",
        "children": []
      }
    ],
    "path": "/docs/reverting/bootableusb.html",
    "pathLocale": "/",
    "extraFields": [
      "Making a Bootable USB"
    ]
  },
  {
    "title": "Booting the Recovery USB",
    "headers": [],
    "path": "/docs/reverting/booting-recovery-usb.html",
    "pathLocale": "/",
    "extraFields": [
      "Booting the Recovery USB"
    ]
  },
  {
    "title": "Restoring the Stock ChromeOS Firmware",
    "headers": [
      {
        "level": 2,
        "title": "Requirements",
        "slug": "requirements",
        "link": "#requirements",
        "children": []
      },
      {
        "level": 2,
        "title": "Restoring",
        "slug": "restoring",
        "link": "#restoring",
        "children": []
      }
    ],
    "path": "/docs/reverting/flashing-stock.html",
    "pathLocale": "/",
    "extraFields": [
      "Restoring the Stock ChromeOS Firmware"
    ]
  },
  {
    "title": "Reverting to Stock ChromeOS",
    "headers": [
      {
        "level": 2,
        "title": "Requirements",
        "slug": "requirements",
        "link": "#requirements",
        "children": []
      }
    ],
    "path": "/docs/reverting/",
    "pathLocale": "/",
    "extraFields": [
      "Reverting to Stock ChromeOS"
    ]
  },
  {
    "title": "Making a Recovery USB",
    "headers": [
      {
        "level": 2,
        "title": "Chromebook Recovery Utility (Windows/ChromeOS/macOS)",
        "slug": "chromebook-recovery-utility-windows-chromeos-macos",
        "link": "#chromebook-recovery-utility-windows-chromeos-macos",
        "children": []
      },
      {
        "level": 2,
        "title": "Manually Flash Recovery Image (Windows/ChromeOS/macOS/Linux)",
        "slug": "manually-flash-recovery-image-windows-chromeos-macos-linux",
        "link": "#manually-flash-recovery-image-windows-chromeos-macos-linux",
        "children": [
          {
            "level": 3,
            "title": "Windows",
            "slug": "windows",
            "link": "#windows",
            "children": []
          },
          {
            "level": 3,
            "title": "macOS/ChromeOS/Linux",
            "slug": "macos-chromeos-linux",
            "link": "#macos-chromeos-linux",
            "children": []
          }
        ]
      }
    ],
    "path": "/docs/reverting/making-recovery-usb.html",
    "pathLocale": "/",
    "extraFields": [
      "Making a Recovery USB"
    ]
  },
  {
    "title": "Compiling Your Own Firmware",
    "headers": [],
    "path": "/docs/support/compiling.html",
    "pathLocale": "/",
    "extraFields": [
      "Compiling Your Own Firmware"
    ]
  },
  {
    "title": "Debugging",
    "headers": [
      {
        "level": 2,
        "title": "Grabbing Logs",
        "slug": "grabbing-logs",
        "link": "#grabbing-logs",
        "children": []
      },
      {
        "level": 2,
        "title": "Firmware",
        "slug": "firmware",
        "link": "#firmware",
        "children": []
      },
      {
        "level": 2,
        "title": "SuzyQable Debug Cable",
        "slug": "suzyqable-debug-cable",
        "link": "#suzyqable-debug-cable",
        "children": []
      },
      {
        "level": 2,
        "title": "ACPI and Linux kernelspace",
        "slug": "acpi-and-linux-kernelspace",
        "link": "#acpi-and-linux-kernelspace",
        "children": []
      },
      {
        "level": 2,
        "title": "gsctool",
        "slug": "gsctool",
        "link": "#gsctool",
        "children": []
      }
    ],
    "path": "/docs/support/debugging.html",
    "pathLocale": "/",
    "extraFields": [
      "Debugging"
    ]
  },
  {
    "title": "Help & Support",
    "headers": [
      {
        "level": 2,
        "title": "Firmware/Device Support Requests",
        "slug": "firmware-device-support-requests",
        "link": "#firmware-device-support-requests",
        "children": []
      },
      {
        "level": 2,
        "title": "Unbricking Service",
        "slug": "unbricking-service",
        "link": "#unbricking-service",
        "children": []
      }
    ],
    "path": "/docs/support/",
    "pathLocale": "/",
    "extraFields": [
      "Help & Support"
    ]
  },
  {
    "title": "Disabling Firmware Write Protection",
    "headers": [
      {
        "level": 2,
        "title": "Removing the Write Protection Screw",
        "slug": "removing-the-write-protection-screw",
        "link": "#removing-the-write-protection-screw",
        "children": []
      },
      {
        "level": 2,
        "title": "Disconnecting the Battery",
        "slug": "disconnecting-the-battery",
        "link": "#disconnecting-the-battery",
        "children": []
      },
      {
        "level": 2,
        "title": "Bridging a Jumper",
        "slug": "bridging-a-jumper",
        "link": "#bridging-a-jumper",
        "children": []
      },
      {
        "level": 2,
        "title": "Using Closed-Case Debugging (CCD) / Using a SuzyQable",
        "slug": "using-closed-case-debugging-ccd-using-a-suzyqable",
        "link": "#using-closed-case-debugging-ccd-using-a-suzyqable",
        "children": [
          {
            "level": 3,
            "title": "Step 1: Enabling Closed Case Debugging (CCD)",
            "slug": "step-1-enabling-closed-case-debugging-ccd",
            "link": "#step-1-enabling-closed-case-debugging-ccd",
            "children": []
          },
          {
            "level": 3,
            "title": "Step 2: Disabling Write Protection",
            "slug": "step-2-disabling-write-protection",
            "link": "#step-2-disabling-write-protection",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Disable AP RO Firmware Verification",
        "slug": "disable-ap-ro-firmware-verification",
        "link": "#disable-ap-ro-firmware-verification",
        "children": [
          {
            "level": 3,
            "title": "Step 1: Verify the device has a Ti50",
            "slug": "step-1-verify-the-device-has-a-ti50",
            "link": "#step-1-verify-the-device-has-a-ti50",
            "children": []
          },
          {
            "level": 3,
            "title": "Step 2: Disable RO verification",
            "slug": "step-2-disable-ro-verification",
            "link": "#step-2-disable-ro-verification",
            "children": []
          },
          {
            "level": 3,
            "title": "Recovering a device bricked due to RO verification",
            "slug": "recovering-a-device-bricked-due-to-ro-verification",
            "link": "#recovering-a-device-bricked-due-to-ro-verification",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Disabling Software Write Protection",
        "slug": "disabling-software-write-protection",
        "link": "#disabling-software-write-protection",
        "children": []
      }
    ],
    "path": "/docs/firmware/wp/disabling.html",
    "pathLocale": "/",
    "extraFields": [
      "Disabling Firmware Write Protection"
    ]
  },
  {
    "title": "Firmware Write Protection on ChromeOS Devices",
    "headers": [
      {
        "level": 2,
        "title": "How Does Firmware Write Protection Work?",
        "slug": "how-does-firmware-write-protection-work",
        "link": "#how-does-firmware-write-protection-work",
        "children": [
          {
            "level": 3,
            "title": "Software write protection",
            "slug": "software-write-protection",
            "link": "#software-write-protection",
            "children": []
          },
          {
            "level": 3,
            "title": "Hardware Write Protection",
            "slug": "hardware-write-protection",
            "link": "#hardware-write-protection",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Why Disable Firmware Write Protection?",
        "slug": "why-disable-firmware-write-protection",
        "link": "#why-disable-firmware-write-protection",
        "children": [
          {
            "level": 3,
            "title": "GBB Flags",
            "slug": "gbb-flags",
            "link": "#gbb-flags",
            "children": []
          },
          {
            "level": 3,
            "title": "Custom Firmware",
            "slug": "custom-firmware",
            "link": "#custom-firmware",
            "children": []
          }
        ]
      }
    ],
    "path": "/docs/firmware/wp/",
    "pathLocale": "/",
    "extraFields": [
      "Firmware Write Protection on ChromeOS Devices"
    ]
  },
  {
    "title": "Unbricking",
    "headers": [
      {
        "level": 2,
        "title": "Unbricking with a ch341a USB programmer",
        "slug": "unbricking-with-a-ch341a-usb-programmer",
        "link": "#unbricking-with-a-ch341a-usb-programmer",
        "children": []
      },
      {
        "level": 2,
        "title": "Unbricking with a SuzyQable (aka SuzyQ cable)",
        "slug": "unbricking-with-a-suzyqable-aka-suzyq-cable",
        "link": "#unbricking-with-a-suzyqable-aka-suzyq-cable",
        "children": []
      }
    ],
    "path": "/docs/support/unbricking/",
    "pathLocale": "/",
    "extraFields": [
      "Unbricking"
    ]
  },
  {
    "title": "Unbricking/Flashing with a ch341a USB Programmer",
    "headers": [
      {
        "level": 2,
        "title": "Requirements",
        "slug": "requirements",
        "link": "#requirements",
        "children": []
      },
      {
        "level": 2,
        "title": "Hardware Disassembly",
        "slug": "hardware-disassembly",
        "link": "#hardware-disassembly",
        "children": []
      },
      {
        "level": 2,
        "title": "Prepping to Flash",
        "slug": "prepping-to-flash",
        "link": "#prepping-to-flash",
        "children": [
          {
            "level": 3,
            "title": "Persisting the board's Vital Product Data (VPD) and Hardware ID (HWID)",
            "slug": "persisting-the-board-s-vital-product-data-vpd-and-hardware-id-hwid",
            "link": "#persisting-the-board-s-vital-product-data-vpd-and-hardware-id-hwid",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Flashing Your Device",
        "slug": "flashing-your-device",
        "link": "#flashing-your-device",
        "children": [
          {
            "level": 3,
            "title": "Clean Up",
            "slug": "clean-up",
            "link": "#clean-up",
            "children": []
          }
        ]
      }
    ],
    "path": "/docs/support/unbricking/unbrick-ch341a.html",
    "pathLocale": "/",
    "extraFields": [
      "Unbricking/Flashing with a ch341a USB Programmer"
    ]
  },
  {
    "title": "Unbricking/Flashing with a SuzyQable",
    "headers": [
      {
        "level": 2,
        "title": "Requirements",
        "slug": "requirements",
        "link": "#requirements",
        "children": []
      },
      {
        "level": 2,
        "title": "Hardware Disassembly",
        "slug": "hardware-disassembly",
        "link": "#hardware-disassembly",
        "children": []
      },
      {
        "level": 2,
        "title": "Prepping to Flash",
        "slug": "prepping-to-flash",
        "link": "#prepping-to-flash",
        "children": [
          {
            "level": 3,
            "title": "Persisting the board's Vital Product Data (VPD) and Hardware ID (HWID)",
            "slug": "persisting-the-board-s-vital-product-data-vpd-and-hardware-id-hwid",
            "link": "#persisting-the-board-s-vital-product-data-vpd-and-hardware-id-hwid",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Flashing Your Device",
        "slug": "flashing-your-device",
        "link": "#flashing-your-device",
        "children": [
          {
            "level": 3,
            "title": "Clean Up",
            "slug": "clean-up",
            "link": "#clean-up",
            "children": []
          }
        ]
      }
    ],
    "path": "/docs/support/unbricking/unbrick-suzyq.html",
    "pathLocale": "/",
    "extraFields": [
      "Unbricking/Flashing with a SuzyQable"
    ]
  },
  {
    "title": "",
    "headers": [],
    "path": "/404.html",
    "pathLocale": "/",
    "extraFields": [
      ""
    ]
  }
]
