<template><div><h1 id="known-issues" tabindex="-1"><a class="header-anchor" href="#known-issues"><span>Known Issues</span></a></h1>
<h2 id="devices" tabindex="-1"><a class="header-anchor" href="#devices"><span>Devices</span></a></h2>
<div class="hint-container tip">
<p class="hint-container-title">NOTE</p>
<p>You should probably avoid buying any devices with severe issues, and consider selling if you have one.</p>
</div>
<h3 id="severe-issues" tabindex="-1"><a class="header-anchor" href="#severe-issues"><span>Severe Issues</span></a></h3>
<ul>
<li>Samsung Chromebook 3 (CELES): Numerous hardware bugs that make it difficult to use.</li>
<li>Samsung Galaxy Chromebook (KOHAKU): Badly designed hardware, deteriorating trackpad grounding, possibly faulty backlight circuit.</li>
</ul>
<h3 id="minor-issues" tabindex="-1"><a class="header-anchor" href="#minor-issues"><span>Minor Issues</span></a></h3>
<ul>
<li>Samsung Chromebox Series 3 (STUMPY): DisplayPort output is non-functional under Windows, and VGA output through the DVI port is non-functional.</li>
</ul>
<h2 id="firmware" tabindex="-1"><a class="header-anchor" href="#firmware"><span>Firmware</span></a></h2>
<p>The issues listed below are longstanding and unlikely to change. The Github <a href="https://github.com/MrChromebox/firmware/issues/" target="_blank" rel="noopener noreferrer">Firmware Issue Tracker</a></p>
<ul>
<li>
<p><strong>All ARM-based  Chromebooks</strong>: The MrChromebox project does not support these devices at all.</p>
</li>
<li>
<p>GeminiLake: Legacy boot mode/alternative firmware menu works but does not show payload list</p>
</li>
<li>
<p>AMD Stoneyridge: Most Stoney Ridge devices do not currently have functional Legacy Boot Mode due to bugs in Google's firmware.</p>
</li>
<li>
<p>AMD Cezanne: Legacy Boot Mode/Alternative Firmware menu works, edk2 boots, but screen backlight is off. Will hopefully be fixed in next Google fw update (fingers crossed).</p>
</li>
</ul>
<div class="hint-container tip">
<p class="hint-container-title">NOTE</p>
<p>For known issues relating to OS support, see the <a href="https://docs.chrultrabook.com/docs/installing/known-issues.md" target="_blank" rel="noopener noreferrer">chrultrabook documentation</a>.</p>
</div>
</div></template>


