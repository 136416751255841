<template><div><h1 id="restoring-the-stock-chromeos-firmware" tabindex="-1"><a class="header-anchor" href="#restoring-the-stock-chromeos-firmware"><span>Restoring the Stock ChromeOS Firmware</span></a></h1>
<div class="hint-container warning">
<p class="hint-container-title">Warning</p>
<p>If your device has hit AUE/EOL, you will not be able to revert it to ChromeOS.</p>
</div>
<h2 id="requirements" tabindex="-1"><a class="header-anchor" href="#requirements"><span>Requirements</span></a></h2>
<ul>
<li>Linux installed on the device, or a <RouteLink to="/docs/reverting/bootableusb.html">bootable Linux live USB</RouteLink>.</li>
<li>Network connectivity</li>
<li>A USB containing your stock firmware backup image, or</li>
<li>A ChromeOS <RouteLink to="/docs/reverting/making-recovery-usb.html">Recovery USB</RouteLink></li>
</ul>
<h2 id="restoring" tabindex="-1"><a class="header-anchor" href="#restoring"><span>Restoring</span></a></h2>
<ol>
<li>
<p>Boot Linux</p>
</li>
<li>
<p>Ensure <code v-pre>curl</code> is installed</p>
<ul>
<li>For Ubuntu-based distros, run: <code v-pre>sudo apt update &amp;&amp; sudo apt install -y curl</code></li>
</ul>
</li>
<li>
<p>Run the <RouteLink to="/docs/fwscript.html">Firmware Utility Script</RouteLink>:</p>
<ul>
<li><code v-pre>cd; curl -LO mrchromebox.tech/firmware-util.sh &amp;&amp; sudo bash firmware-util.sh</code></li>
</ul>
</li>
<li>
<p>Choose option 2: <code v-pre>Restore Stock ChromeOS Firmware</code>.</p>
</li>
<li>
<p>Follow the prompts. Reboot after successful completion.</p>
</li>
</ol>
<p>With the stock ChromeOS firmware restored, you can now perform a <RouteLink to="/docs/reverting/booting-recovery-usb.html">ChromeOS USB recovery</RouteLink> to reload ChromeOS.</p>
</div></template>


