<template><div><h1 id="unbricking" tabindex="-1"><a class="header-anchor" href="#unbricking"><span>Unbricking</span></a></h1>
<p>If after flashing the firmware and rebooting, you've waited a good 60s-90s and your device won't boot, you may need to unbrick it - a process which involves re-flashing the firmware using an external programmer.</p>
<div class="hint-container tip">
<p class="hint-container-title">Tips</p>
<p>If you think your device is bricked, the quickest and easist thing you can do is to perform a <code v-pre>Hard Reset</code> (AKA an EC Reset): press the <code v-pre>[Refresh+Power]</code> keys simultanenously, then release.</p>
</div>
<p>There are serveral methods which can be used, though not all apply to all devices. All 2017+ models with a CR50 Google Security Chip (GSC) can be recovered with a SuzyQable.</p>
<h2 id="unbricking-with-a-ch341a-usb-programmer" tabindex="-1"><a class="header-anchor" href="#unbricking-with-a-ch341a-usb-programmer"><span><a href="unbrick-ch341a">Unbricking with a ch341a USB programmer</a></span></a></h2>
<h2 id="unbricking-with-a-suzyqable-aka-suzyq-cable" tabindex="-1"><a class="header-anchor" href="#unbricking-with-a-suzyqable-aka-suzyq-cable"><span><a href="unbrick-suzyq">Unbricking with a SuzyQable (aka SuzyQ cable)</a></span></a></h2>
</div></template>


