<template><div><h1 id="debugging" tabindex="-1"><a class="header-anchor" href="#debugging"><span>Debugging</span></a></h1>
<h2 id="grabbing-logs" tabindex="-1"><a class="header-anchor" href="#grabbing-logs"><span>Grabbing Logs</span></a></h2>
<ul>
<li>If you're experiencing a firmware problem, provide output from <code v-pre>cbmem</code> console.
<ul>
<li>If you're running Linux, the output from <code v-pre>dmesg</code>, and <code v-pre>dmidecode</code> can also be helpful</li>
</ul>
</li>
<li>If you're experiencing OS-related issues, please ask for help using the <a href="https://forum.chrultrabook.com/" target="_blank" rel="noopener noreferrer">Chrultrabook Forums</a>.</li>
</ul>
<h2 id="firmware" tabindex="-1"><a class="header-anchor" href="#firmware"><span>Firmware</span></a></h2>
<p>To debug firmware, you will need the following tools:</p>
<ul>
<li><code v-pre>cbmem</code> utility - to obtain coreboot boot logs</li>
<li>A SuzyQable - If you're experiencing severe system instability (lockups, crashes), preventing you from on-device debugging.</li>
</ul>
<p>To dump the cbmem buffer, follow these steps:</p>
<ol>
<li>
<p>Download the cbmem utility:</p>
<ul>
<li><code v-pre>wget https://mrchromebox.tech/files/util/cbmem.tar.gz</code></li>
</ul>
</li>
<li>
<p>Unzip the file and give the binary executable permissions:</p>
<ul>
<li><code v-pre>tar -xf cbmem.tar.gz;rm cbmem.tar.gz;chmod +x cbmem</code></li>
</ul>
</li>
<li>
<p>Run <code v-pre>cbmem</code> and redirect the output to <code v-pre>cbmem.log</code> file:</p>
<ul>
<li><code v-pre>sudo ./cbmem -1 &gt; cbmem.log</code></li>
</ul>
</li>
<li>
<p>Provide the <code v-pre>cbmem.log</code> file via the chrultrabook forums, Github issue tracker, or email for further analysis.</p>
</li>
</ol>
<div class="hint-container tip">
<p class="hint-container-title">NOTE</p>
<p>The parameter following the cbmem command is the number one (-1), not the letter l (-l).</p>
</div>
<h2 id="suzyqable-debug-cable" tabindex="-1"><a class="header-anchor" href="#suzyqable-debug-cable"><span>SuzyQable Debug Cable</span></a></h2>
<p>The SuzyQable provides three serial ports (UARTs) which can be accessed using a terminal utility such as <code v-pre>minicom</code> or <code v-pre>picocom</code>:</p>
<ol>
<li>CR50 console under /dev/ttyUSB0:
<ul>
<li><code v-pre>minicom -D /dev/ttyUSB0</code></li>
</ul>
</li>
<li>CPU console under /dev/ttyUSB1:
<ul>
<li><code v-pre>minicom -D /dev/ttyUSB1</code></li>
</ul>
</li>
<li>Embedded Controller (EC) console under /dev/ttyUSB2
<ul>
<li><code v-pre>minicom -D /dev/ttyUSB2</code></li>
</ul>
</li>
</ol>
<ul>
<li>
<p>The CPU console can be used to debug coreboot, edk2, or the Linux kernel, but you need to re-compile coreboot with following configuration options enabled:
<code v-pre>CONSOLE_SERIAL=y</code>
<code v-pre>EDK2_SERIAL_SUPPORT=y</code></p>
</li>
<li>
<p>For kernel debugging, you will also need to append the following to your kernel command line:
<code v-pre>loglevel=15 console=ttyS4,115200n8</code></p>
</li>
</ul>
<h2 id="acpi-and-linux-kernelspace" tabindex="-1"><a class="header-anchor" href="#acpi-and-linux-kernelspace"><span>ACPI and Linux kernelspace</span></a></h2>
<ol>
<li>
<p>Download the chrultrabook debugging script.</p>
<ul>
<li><code v-pre>cd ~/Desktop;wget https://raw.githubusercontent.com/chrultrabook/linux-tools/main/debugging.sh</code></li>
</ul>
<p>It will dump:</p>
<ul>
<li>ACPI tables from sysfs (<code v-pre>/sys/firmware/acpi/*</code>)</li>
<li>DMI information (<code v-pre>dmidecode</code>)</li>
<li>Coreboot buffer (<code v-pre>cbmem</code>)</li>
<li>Linux kernel logs (<code v-pre>dmesg</code>)</li>
<li>List of PCI devices (<code v-pre>lspci</code>)</li>
<li>List of USB devices (<code v-pre>lsusb</code>)</li>
<li>Information about soundcards present in the system and their configuration</li>
</ul>
<p>Into <code v-pre>debug-logs.tar.gz</code> archive on your desktop.</p>
</li>
<li>
<p>Run it: <code v-pre>chmod +x debugging.sh;./debugging.sh</code></p>
</li>
<li>
<p>Upload this file if you need help with troubleshooting.</p>
</li>
</ol>
<h2 id="gsctool" tabindex="-1"><a class="header-anchor" href="#gsctool"><span>gsctool</span></a></h2>
<p>gsctool is used to communicate with the GSC (Google Security Chip) from ChromeOS and is usually used to unlock CCD without having to open up the device.</p>
<p>View status:</p>
<ul>
<li><code v-pre>sudo gsctool -a -I</code></li>
</ul>
<p>Unlock CCD:</p>
<ul>
<li><code v-pre>sudo gsctool -a -o</code></li>
<li>This will prompt you multiple times over several minutes to press the power button. After the last time, the device will reboot back into Verified Boot Mode</li>
</ul>
</div></template>


